.img {
  max-width: 100%;
  width: 150px;
  margin-top: 20px;
}

.avt div div {
  overflow: hidden;
}

.eyeIcon {
  position: absolute;
  right: 5px;
  top: 58%;
  cursor: pointer;
  color: var(--secondary) !important;
  z-index: 99;
}
