.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--primary) !important;
  position: relative;
  font-size: 18px;
}

.link {
  text-decoration: none;
  color: #000 !important;
  font-weight: 600;
  font-size: 16px;
  padding: 0 0;
  display: inline-block;
  transition: 0.3s all ease-in-out;
}

.link:hover {
  color: var(--white) !important;
  transition: 0.3s all ease-in-out;
}

.copy {
  color: var(--dark) !important;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s all ease-in-out;
}

.copy:hover {
  color: var(--white) !important;
  transition: 0.3s all ease-in-out;
}

@media screen and (max-width: 668px) {
  .wrapper {
    padding-top: 0;
  }
  .pt {
    padding-top: 0;
  }

  .phone {
    position: relative;
  }

  .content {
    padding: 15px;
    height: auto;
  }

  .w100 {
    width: 100% !important;
  }

  .input,
  .input::placeholder {
    font-size: 16px !important;
  }
}
