.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--secondary-100);
  color: var(--primary);
  margin-left: 20px;
  margin-right: 20px;
}

.icon.active {
  background: var(--primary);
  color: var(--white);
  transition: 0.3s all ease-in-out;
}

.icon.active:hover {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  background: var(--primary-500);
}

.text {
  color: var(--primary-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
