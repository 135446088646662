.wrapper {
  background: var(--white) !important;
  min-height: 100vh;
}

.logo {
  max-height: 65px;
}

.nav .nav__item {
  padding: 0.6rem 0;
  text-decoration: none !important;
  font-weight: 600;
  color: var(--secondary) !important;
  transition: 0.3s all ease-in-out;
  display: grid;
  grid-template-columns: 35px 1fr;
  font-size: 14px;
  cursor: pointer !important;
}

.nav .nav__item:global(.active) {
  color: var(--dark) !important;
}

.nav__item_text {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.nav__item:global(.active) .icon {
  background: linear-gradient(
    90deg,
    var(--primary) 0%,
    var(--primary-hover) 100%
  ) !important;
  border-radius: 50%;
  color: var(--white) !important;
}
.icon {
  height: 35px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__item:hover {
  color: var(--dark) !important;
  transition: 0.3s all ease-in-out;
}

.right__item {
  text-decoration: none !important;
  color: var(--secondary) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid var(--secondary);
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}

.right__item:hover {
  color: var(--dark) !important;
  border: 1px solid var(--dark);
  transition: 0.3s all ease-in-out;
}

.ham {
  display: none;
}

:global(.mantine-Accordion-item) {
  color: var(--secondary) !important;
  font-family: "Montserrat" !important;
}
:global(.mantine-Accordion-item .mantine-Accordion-label) {
  border-bottom: none !important;
  color: var(--secondary) !important;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .wrapper {
    display: block;
    position: fixed;
    z-index: 9;
    width: 100%;
    left: -105%;
    min-height: 100vh !important;
    transition: 0.3s all ease-in-out;
  }

  .wrapper.active {
    left: 0;
    transition: 0.3s all ease-in-out;
  }

  .nav__item {
    padding: 0.2rem 0 !important;
  }

  .ham {
    display: block;
    text-decoration: none !important;
    color: var(--dark) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid var(--dark);
    margin-right: 10px;
    transition: 0.3s all ease-in-out;
  }
}
